var Webflow = Webflow || [];
Webflow.push(function () {
  var tabTimeout;
  clearTimeout(tabTimeout);
  tabLoop();
  function tabLoop() {
    tabTimeout = setTimeout(function () {
      var $next = $('.section-how__tabs-menu')
        .children('.w--current:first')
        .next();
      if ($next.length) {
        if ($('.menu-button').hasClass('w--open')) {
          tabLoop();
        } else {
          $next.removeAttr('href').click();
        }
      } else {
        if ($('.menu-button').hasClass('w--open')) {
          tabLoop();
        } else {
          $('.tab-link:first').removeAttr('href').click();
        }
      }
    }, 5000);
  }
  $('.tab-link').click(function () {
    clearTimeout(tabTimeout);
    tabLoop();
  });
});
